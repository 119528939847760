<template>
  <div class="container-fluid products-categories-create">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <div class="col-auto my-auto d-flex align-items-center">
              <h5>Add category</h5>
            </div>
          </div>

          <div class="card-body">
            <div v-if="!loading" class="row mt-4 mb-4">
              <div class="col">

                <form @submit.prevent="saveCategory">
                  <div class="row mb-5">
                    <div class="col-6">
                      <label for="title">Title*</label>
                      <input
                        type="text"
                        id="title"
                        v-model="category.title"
                        class="form-control"
                        placeholder="Title"
                        required
                      />
                    </div>
                  </div>

                  <div class="row pagination">
                    <div class="col">
                      <button
                        type="button"
                        @click.stop="backToList()"
                        :disabled="loading"
                        class="btn btn-sm btn-outline-secondary btn-back me-2">
                        <i class="far fa-arrow-left me-1"></i>
                        Back
                      </button>
                    </div>

                    <div class="col text-end">
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-sm btn-outline-primary"
                      >
                        <i class="far fa-save me-1"></i> Save
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>

            <div v-else class="card border-0 shadow-none mb-4 pt-3">
              <loading></loading>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import Loading from "@/views/components/loader/Loading";

  export default {
    props: [],
    data() {
      return {
        category: {
          title: '',
        },
        loading: false,
      }
    },
    methods: {
      backToList() {
        this.$router.push('/settings/products/categories');
      },
      saveCategory() {
        var url = process.env.VUE_APP_API_URL + "/products/categories";

        this.$axios.post(
          url,
          { title: this.category.title, }
        ).then(({ data }) => {
          if (data.message === "Category created") {
            this.$router.push("/settings/products/categories");
          }
          this.$EventBus.$emit("alert", data);
        });
      },
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
      path() {
        return this.$stash.path;
      }
    },
    components: {
      Loading,
    }
  }
</script>

<style scoped>
  @media (max-width: 768px) {
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%
    }

    .col-6 > label {
      margin-top: 1rem;
    }

    .pagination {
      flex-direction: column;
    }

    .btn-back, .btn-outline-primary,
    .btn-outline-danger {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
</style>